/**
 * 产业基金相关接口
 */
 //@ts-ignore
 import { $get, $post, $dataPost, $put } from "../../../http/axios"
 /** @desc 产业基金申报表单 */
 export interface IndustryFundDeclare{
    [key: string]:any,
    /** @desc 申请机构名称(管理机构) */
    applicantManageOrgName: string
    /** @desc 拟合作设立基金名称 */
    willCooperateFundName: string
    /** @desc 拟合作设立基金规模 */
    willCooperateFundScale: string
    /** @desc 拟申请额度 */
    willApplicantAmount: string
    /** @desc 基金存续期 */
    fundDuration: string
    /** @desc 申请机构注册地址 */
    fregisterAddress: string
    /** @desc 申请机构法人代表-姓名 */
    legalPerson: string
    /** @desc 申请机构联系人-职务 */
    linkManTitle: string
    /** @desc 申请机构联系人-办公电话 */
    linkManOfficePhone: string
    /** @desc 申请机构联系人-手机 */
    linkManMobilePhone: string
    /** @desc 申请机构联系人-传真 */
    linkManFax: string
    /** @desc 申请机构联系人-邮箱 */
    linkManEmail: string
    /** @desc 申请机构联系人-通讯地址 */
    linkManPostalAddress: string
    /** @desc 申请机构注册资本 */
    registCapital: string
    /** @desc 申请机构实收资本 */
    actualCapital: string
    /** @desc 累计设立基金规模 */
    totalFundScale: string
    /** @desc 拟合作设立基金投资策略-投资领域和资金比例 */
    willInvestFieldCapitalRatio: string
    /** @desc 拟合作设立基金投资策略-投资地域 */
    willInvestField: string
    /** @desc 拟合作设立基金投资策略-投资阶段和资金比例 */
    willInvestStageCapitalRatio: string
    /** @desc 已投资项目情况-累计项目数量 */
    investProjectNum: string
    /** @desc 已投资项目情况-累计投资金额 */
    investTotalMoney: string
    /** @desc 已投资初创期科技型中小企业情况-累计项目数量 */
    technologySmallProjectNum: string
    /** @desc 已投资初创期科技型中小企业情况-累计投资金额 */
    technologySmallTotalMoney: string
    /** @desc 已投资初创期科技型中小企业情况-数量比例 */
    technologySmallNumRatio: string
    /** @desc 已投资初创期科技型中小企业情况-金额比例 */
    technologySmallMoneyRatio: string
    /** @desc 已投资初创期科技型中小企业情况-获得后续增值融资项目数 */
    technologySmallValueAddedProjectNum: string
    /** @desc 高管职业道德承诺书 */
    executiveProfessionalEthicsCommitmentList: API.FileList
    /** @desc 申请材料真实性证明 */
    certificateAuthenticityApplicationMaterialsList: API.FileList
    /** @desc 募集资金来源和对象合法性声明 */
    statementOnLegalitySourcesObjectsRaisedFundsList: API.FileList
     
 }

/**
 * @desc 获取产业基金申报列表
 * @param {number} page  当前页码
 * @param {number} rows  每页行数
 * @param {number} size  同上
 * @returns 
 */
export async  function getIndustryFundDeclareList(pager:API.Pager,condition:API.Form = {}):Promise<any>{
    return await  $post(`/industryfunddeclare/findIndustryFundDeclarePage`,{...pager,condition:JSON.stringify(condition)})
}
/**
 * @desc 企业基金申报表单保存模式
 */
export enum IndustryFundDeclareSaveMode{
    /** @desc 仅保存 */
    ONLY_SAVE=0,
    /** @desc 保存并提交 */
    SAVE_SUBMIT=1
}
/** @desc 产业基金申报状态 */
export enum IndustryFundDeclareStatus{
    /** @desc 草稿 */
    DRAFT="0",
    /** @desc 未读 */
    UNREAD="1",
    /** @desc 已读 */
    READ="2"
    
}
interface SaveIndustryFundDeclareParams{
    /** @desc 表单内容 */
    data:IndustryFundDeclare,
    /** @desc 保存id 可选 没有时 则是新增 有时则是更新 */
    id?:number,
    /** @desc 保存模式 */
    mode:IndustryFundDeclareSaveMode
}

/** @desc 申报表ID */
type IndustryFundDeclareId = number | string
/**
 * @desc 申报表单保存
 * @returns 
 */
export async function saveIndustryFundDeclare({data,id,mode}:SaveIndustryFundDeclareParams):Promise<API.BaseResponse<IndustryFundDeclareId>> {
    let form:IndustryFundDeclare  = Object.assign({},data) //浅拷贝一份 避免影响原数据
    for(let key in form){
        if(form[key] instanceof Object){
            console.log(key,`是对象`,form[key])
            form[key] = JSON.stringify(form[key])
        }
    }
    return await  $post(`/industryfunddeclare/editIndustryFundDeclare`,{
        ...form,
        id,
        industryFundDeclareStatus:mode
    })
}

/**
 * 
 * @param id 申报id
 * @returns 
 */
export async function getIndustryFundDeclare(id:string | number):Promise<IndustryFundDeclare>{
    let data =  await  $post(`/industryfunddeclare/getIndustryFundDeclareById`,{id})
    return data.result
}
