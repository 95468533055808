


































import { getIndustryFundDeclare, IndustryFundDeclare, IndustryFundDeclareSaveMode, saveIndustryFundDeclare } from '@/common/api/modules/industrialFund';
import Forms, { FormItemType, FormsOptionsBlock } from "./components/forms.vue";
import { getStorage, setStorage } from '@/common/utils';
export default {
  watch:{
    declareForm:{
      deep:true,
      handler(nv:IndustryFundDeclare){
       
      }
    }
  },
  data() {
    return {
      activeName: "basicInfo",
      readStorageFlag:false,
      list: [{ label: "申报", name: "basicInfo" }],
      declareForm: {
        applicantManageOrgName: '',
        willCooperateFundName: '',
        willCooperateFundScale: '',
        willApplicantAmount: '',
        fundDuration: '',
        fregisterAddress: '',
        legalPerson: '',
        linkManTitle: '',
        linkManOfficePhone: '',
        linkManMobilePhone: '',
        linkManFax: '',
        linkManEmail: '',
        linkManPostalAddress: '',
        registCapital: '',
        actualCapital: '',
        totalFundScale: '',
        willInvestFieldCapitalRatio: '',
        willInvestField: '',
        willInvestStageCapitalRatio: '',
        investProjectNum: '',
        investTotalMoney: '',
        technologySmallProjectNum: '',
        technologySmallTotalMoney: '',
        technologySmallNumRatio: '',
        technologySmallMoneyRatio: '',
        technologySmallValueAddedProjectNum: '',
        filingNo: '',
        filingTermValidity: '',
        executiveProfessionalEthicsCommitmentList: [],
        certificateAuthenticityApplicationMaterialsList: [],
        statementOnLegalitySourcesObjectsRaisedFundsList: [],
      } as IndustryFundDeclare,
      formsOptions: [
        {
          title: "基本信息",
          items: [
            [
              {
                label: "申请机构名称(管理机构)",
                key: "applicantManageOrgName",
              },
              { label: "拟合作设立基金名称", key: "willCooperateFundName" },
            ],
            [
              {
                label: "拟合作设立基金规模",
                key: "willCooperateFundScale",
                suffix: "万元",
              },
              {
                label: "拟申请额度",
                key: "willApplicantAmount",
                suffix: "万元",
              },
            ],
            [
              {
                label: "基金存续期",
                key: "fundDuration",
                type: FormItemType.textarea,
                width: 400,
              },
            ],
            [{ label: "申请机构注册地址", key: "registerAddress" }],
            [{ title: "申请机构法人代表" }],
            [
              { label: "姓名", key: "legalPerson" },
              { label: "职务", key: "linkManTitle" },
              { label: "办公电话", key: "linkManOfficePhone" },
            ],
            [
              { label: "手机", key: "linkManMobilePhone" },
              { label: "传真", key: "linkManFax" },
              { label: "邮箱", key: "linkManEmail" },
            ],
            [{ label: "通讯地址", key: "linkManPostalAddress" }],
            [
              {
                label: "申请机构注册资本",
                key: "registCapital",
                suffix: "万元",
              },
              {
                label: "申请机构实收资本",
                key: "actualCapital",
                suffix: "万元",
              },
            ],
            [{ label: "累计设立基金规模", key: "totalFundScale" }],
            [{ title: "拟合作基金投资策略" }],
            [
              {
                label: "投资领域和资金比例",
                key: "willInvestFieldCapitalRatio",
                type: FormItemType.textarea,
                width: 400,
              },
              {
                label: "投资地域",
                key: "willInvestField",
                type: FormItemType.textarea,
                width: 400,
              },
            ],
            [
              {
                label: "投资阶段和资金比例",
                key: "willInvestStageCapitalRatio",
                type: FormItemType.textarea,
                width: 400,
              },
            ],
            [{ title: "已投资项目情况" }],
            [
              { label: "累计项目数量", key: "investProjectNum" },
              { label: "累计投资金额", key: "investTotalMoney" },
            ],
            [{ title: "已投资初创期科技型中小企业情况", width: "300px" }],
            [
              { label: "累计项目数量", key: "technologySmallProjectNum" },
              { label: "累计投资金额", key: "technologySmallTotalMoney" },
            ],
            [
              { label: "数量比例", key: "technologySmallNumRatio" },
              { label: "金额比例", key: "technologySmallMoneyRatio" },
            ],
            [
              {
                label: "获得后续增值融资项目数",
                key: "technologySmallValueAddedProjectNum",
              },
            ],
            [{ title: "备案情况" }],
            [
              { label: "备案编号", key: "filingNo" },
              { label: "有效期限", key: "filingTermValidity" },
            ],
          ],
        },
        {
          title: "附件信息",
          items: [
            [{ title: "高管职业道德承诺书" }],
            [
              {
                label: "选择文件",
                key: "executiveProfessionalEthicsCommitmentList",
                type: FormItemType.file,
              },
            ],
            [{ title: "申请材料真实性声明" }],
            [
              {
                label: "选择文件",
                key: "certificateAuthenticityApplicationMaterialsList",
                type: FormItemType.file,
              },
            ],
            [{ title: "募集资金来源和对象合法性声明", width: "400px" }],
            [
              {
                label: "选择文件",
                key: "statementOnLegalitySourcesObjectsRaisedFundsList",
                type: FormItemType.file,
              },
            ],
          ],
        },
      ] as FormsOptionsBlock[],
    };
  },
  computed:{
    id():number | undefined{
      return Number(this.$route.params.id)  || undefined
    }
  },
  mounted(){
    if(this.id){
      this.loadData(this.id)
    }
  },
  methods: {
    /** @desc 加载数据 */
    async loadData(id:string | number){
       let form = await getIndustryFundDeclare(id)
       this.declareForm = form
       console.log("加载到的数据",form);
    },
    /** @desc 保存表单 */
    async handleSaveDeclare(submit?:boolean){
      console.log("保存表单",this.declareForm)
      let {success,message,result}  = await saveIndustryFundDeclare({
        id:this.id,
        data:this.declareForm,
        mode:submit ? IndustryFundDeclareSaveMode.SAVE_SUBMIT : IndustryFundDeclareSaveMode.ONLY_SAVE
      })
      console.log("保存结果",success)
      if(success){
         this.$message.success(message)
      }
    },
    /** @desc 从缓存恢复表单数据 */
    async recoverFormDataByStorage(){
      console.log("获取缓存数据",getStorage("INDUSTRY_FUND_DECLARE_FORM"))
      //新增模式下 读缓存
      setTimeout(()=>{
        this.declareForm = getStorage("INDUSTRY_FUND_DECLARE_FORM") || this.declareForm;    
      },100)
    }
  },
  components: {
    Forms,
  },
};
