










































import { getStorage, setStorage } from "@/common/utils";
import Vue, { PropType } from "vue";
import uploader from "./uploader.vue";
type Form ={
  [key:string]:any
}
export enum FormItemType {
  input = "input",
  textarea = "textarea",
  file = "file",
}
export interface FormsOptionsItem {
  label?: string;
  title?: string;
  key?: string;
  suffix?: string;
  type?: FormItemType;
  width?: string | number;
}
export interface FormsOptionsBlock {
  title: string;
  items: FormsOptionsItem[][];
}

const defaultOptions: FormsOptionsBlock[] = [];
const formatFormValue= (target:FormsOptionsBlock[],defaultValue:Form):Object=>target.reduce((form, block) => {
    for (let item of block.items.flat()) {
      if (item.key) {
        form = { ...form, [item.key]: defaultValue[item.key] };
      }
    }
    return form;
}, {});

export default Vue.extend({
  components: { uploader },
  watch: {
    form:{
      deep:true,
      handler(nv){
         if(this.storage && this.name){//如果是新增模式
            setStorage(this.name,nv);//存到缓存中
        }
        this.$emit("input",nv)
      }
    },
    value:{
      deep:true,
      handler(nv){
        this.form = nv
      }
    }
  },
  mounted() {
    let defaultValue = (this.storage && this.name)? getStorage<Form>(this.name) :this.value
    this.form = formatFormValue(this.options,defaultValue);
    if(this.storage && !this.name){
      console.warn("<Forms/>组件:",`开启storage功能后必须设置props.name`)
    }
  },
  data(){
    return {
      form: {} as Form
    }
  },
  computed: {
    values: {
      get(): Object {
        return this.options.reduce((form, block) => {
          for (let item of block.items.flat()) {
            if (item.key) {
              form = { ...form, [item.key]: this.value[item.key] };
            }
          }
          return form;
        }, {});
      },
      set(val:Object){
        console.log("值设置",val)
        this.$emit("input",val)
      }
    },
  },
  props: {
    debug:Boolean,
    /** @desc 表单名称 用户缓存时做键用 全局唯一不要重复 */
    name:String,
    /** @desc 是否开启表单缓存 */
    storage:Boolean,
    options: {
      type: Array as PropType<FormsOptionsBlock[]>,
      default() {
        return defaultOptions;
      },
    },
    value:{
      type:Object,
      default(){
        return {}
      }
    }
  },
  methods:{
    /** @desc 表单调试时填充表单 */
    handleFillForm(){
      for(let key in  this.form){
        this.form[key] = 1
      }
    }
  }
});
