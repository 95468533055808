






















import Vue from "vue"
import { getToken } from "@/http/auth";
import { mapState } from "vuex";
/** @desc <el-uploader>所需的  :file-list 列表项格式 */
type ElFileItem = {
  name:string,
  url:string
}
/** @desc ElUpload文件对象转系统文件对象 */
const elFileToSystemFile= ({name:fileName,url:attachmentUrl}:ElFileItem):API.FileListItem=>({fileName,attachmentUrl})
/** @desc 系统文件对象转ElUpload文件对象 */
const systemFileToElFile = ({fileName:name,attachmentUrl:url}:API.FileListItem):ElFileItem=>({name,url})

export default Vue.extend({
  name: "Uploader",
  data() {
    return {
      files:[] as API.FileList
    };
  },
  watch:{
    value:{
      deep:true,
      handler(nv:API.FileList){
        this.files = nv
      }
    },
    files:{
      deep:true,
      handler(nv:API.FileList){
        this.$emit("input",nv)
      }
    }
  },
  props: {
    value: {
      type: [String,Array],
    },
    /** @desc 当value为Array时 设置改项为 true 则在传出数据前 进行JSON序列化 */
    json:Boolean,
    multiple:{
      type:Boolean,
      default:true
    },
    text: {
      type: String,
      default: "点击上传",
    },
  },
  computed: {
    ...mapState({
      filePrefixUrl: (state:any) => state.fileUrl,
    }),
    uploadFileHeaders() {
      return {
        Authorization: getToken() || "",
      };
    },
    fileList:{
      get():ElFileItem[]{
        return this.files.map(item=>{
          return {
            name:item.fileName,
            url:`${this.filePrefixUrl}${item.attachmentUrl}`
          }
        })


      }
    },
    url: {
      get() {
        // return this.value;
      },
      set(val) {
        // this.$emit("input", val);
        // this.$emit("change", val);
      },
    },
  },
  methods: {
    handlePreviewImage(e:any) {
      console.log("图片预览", e);
    },
    handleUploadeSuccess(e:API.BaseResponse<API.UploadFileResult>) {
      console.log("上传成功", e);
      if(!e.success){
        this.$message.error(e.message);
        return;
      }
      let {fileName,realPaths,showUrlPrefix} = e.result;
      
      const url:string = `${showUrlPrefix}${realPaths.split(",")[0]}`;
      console.log("存储的url", url);
      if(this.multiple ){
       this.files.push({
         fileName:fileName.split(",")[0],
         attachmentUrl:realPaths.split(",")[0]
       })
       this.$message.success("附件上传成功")
       this.$emit("input", this.files )
       return
      }
      this.url = url;
    },
    /** @desc 文件移除 */
    handleRemoveFile(file:ElFileItem,fileList:Array<ElFileItem>){
      console.log("文件移除",file,fileList)
      this.files = fileList.map(elFileToSystemFile)
    }
  },
});
